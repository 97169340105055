@font-face {
    font-family: "Lato";
    src: url("../res/Fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../res/Fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Lato-Black";
    src: url("../res/Fonts/Lato-Black.ttf") format("truetype");
}
